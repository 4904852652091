import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s, globals, colors, alpha } from '../../style'

const TeamItem = ({ data, theme }) => {
  const { name, description, subtitle, image } = data

  return (
    <div css={sContainer}>
      {image && (
        <Img
          //   style={sImage}
          fluid={image.childImageSharp.fluid}
          alt={image.name}
          css={sImage}
        />
      )}
      <div css={sText}>
        <T variant="h2" d={24} m={24} mb={1} bold condensed center>
          {name}
        </T>
        <T
          d={14}
          m={16}
          mb={1}
          bold
          condensed
          center
          extraCss={{ color: colors[theme].main }}>
          {subtitle}
        </T>
        <p class="team-item-desc" center>
          {description}
        </p>
      </div>
    </div>
  )
}

const sContainer = {
  flexShrink: 0,
  position: 'relative',

  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignSelf: 'stretch',
  bacgroundColor: '#fff',
  borderRadius: '0 0 1.5rem 1.5rem',
  [s.sm_down]: {
    boxShadow: globals.shadows.sections.mobile,
  },
  [s.md]: {
    boxShadow: globals.shadows.sections.desktop,
  },
}
const sImage = {
    overflow: 'hidden',
    borderRadius: '1.5rem 1.5rem 0 0',
}
const sText = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  fontSize: '0.875rem',
  '.team-item-desc': {
    opacity: '0.6',
    textAlign: 'center',
  },
  [s.sm_down]: {
    width: `calc(100% - ${globals.spacing.inside.mobile})`,
    padding: globals.spacing.inside.mobile,
  },
  [s.md]: {
    width: `calc(100% - ${globals.spacing.inside.desktop / 2})`,
    padding: globals.spacing.inside.desktop / 2,
  },
}
export default TeamItem
