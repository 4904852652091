import React from 'react'
import { T } from '../../typo'
import { s, globals } from '../../style'
import { graphql, useStaticQuery } from 'gatsby'
import TeamItem from './TeamItem'
import { useLocation } from '@reach/router'

const Team = ({ theme, pageData }) => {
  const data = useStaticQuery(graphql`
    {
      team: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/content/team/"}}
        sort: {fields: frontmatter___order, order: ASC}
      ) {
        edges {
          node {
            frontmatter {
              name
              description
              subtitle
              name
              image {
                childImageSharp {
                fluid(
                    maxWidth: 1280
                    quality: 100
                    toFormat: JPG
                    jpegProgressive: true
                    ) {
                    ...GatsbyImageSharpFluid
                    }
                }
              }
            }
          }
        }
      }
    }
  `)

  const team = data.team.edges.map(({ node }) => node.frontmatter)

  return (
    <div css={sContainer}>
      <div css={sIntro}>
        <T
          d={64}
          m={48}
          bold
          variant="h1"
          extraCss={{
            marginBottom: '2rem',
          }}>
          {pageData.title}
        </T>
        <T>{pageData.description}</T>
      </div>
      <div css={sTeam}>
        {team
          .map((person, id) => (
            <TeamItem
              key={id}
              id={id}
              data={person}
              theme={theme}
            />
          ))}
      </div>
    </div>
  )
}

const sContainer = {
  margin: '0 auto',
  maxWidth: globals.maxWidth,
  [s.lg_1280]: {
    padding: '0 2rem',
  },
  marginBottom: '4rem',
  [s.md]: {
    padding: '0 2rem',
  },
  [s.sm_down]: {
    padding: '0 1.5rem',
  },
}

const sIntro = {
  marginBottom: '8rem',
  paddingTop: '2rem',
  [s.md]: { paddingTop: '2rem' },
  [s.sm_down]: { textAlign: 'center', marginBottom: '4rem' },
}

// const sCategories = {}

// const sCategory = {
//   title: {
//     marginBottom: '4rem',
//     [s.sm_down]: { textAlign: 'center', marginBottom: '4rem' },
//   },
//   marginBottom: '12rem',
//   [s.sm_down]: { marginBottom: '4rem' },
// }

const sTeam = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '2rem',
  [s.md_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '1rem',
  },
}

export default Team
